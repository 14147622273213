import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  ToastService,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  TwoMessage,
  UsersService,
} from 'two-app-ui';
import {faCalendarAlt, faTruckLoading, faList, faPencil, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {Job, MapOf, QueryParameter, TimeLineEvent, User} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import JobsService from '../../services/JobsService';
import TlesService from '../../services/TlesService';
import JobDetail from './JobDetail';
import {ProgressSpinner} from 'primereact/progressspinner';
import EditJobDialog from './EditJobDialog';
import AddEventDialog from './AddEventDialog';

library.add(faCalendarAlt, faTruckLoading, faList);

interface RouteProps {
  id: string;
}

interface State {
  job?: Job;
  loadingJob: boolean;
  loadingSecondaryView: boolean;
  showEditDialog: boolean;
  showAddEventDialog: boolean;
  eventItems: TwoTimelineItem[];
  usersMap: MapOf<User>;
}

class JobComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  toastService: ToastService | null = null;
  jobsService: JobsService | null = null;
  tlesService: TlesService | null = null;
  usersService: UsersService | null = null;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingJob: false,
      loadingSecondaryView: false,
      showEditDialog: false,
      showAddEventDialog: false,
      eventItems: [],
      usersMap: {},
    };

    this.toast = React.createRef();

    this.onHideEditDialog = this.onHideEditDialog.bind(this);
    this.onHideAddEventDialog = this.onHideAddEventDialog.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobsService = this.context.jobsService;
    this.tlesService = this.context.tlesService;
    this.usersService = this.context.usersService;

    const id = this.props.match.params.id;
    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.jobUpdated) {
        this.loadJob(id);
        this.loadEvents(id);
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.props.history.push('/containers');
        }
      }
    });

    this.loadJob(id);
    this.loadEvents(id);
    this.loadUsers();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadJob(id: string) {
    this.setState({loadingJob: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.jobsService
      ?.getJobs(params)
      .then(data => {
        const job = (data.records as Job[])[0];
        this.setState({
          job: job,
          loadingJob: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Job load failed, please try again.');
        this.setState({
          loadingJob: false,
        });
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'job',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];
        const eventItems = events.map(event => {
          return {event: event};
        });
        this.setState({
          eventItems: eventItems,
          loadingSecondaryView: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];
        const usersMap: MapOf<User> = {};
        for (const user of users) {
          usersMap[user.id!] = user;
        }

        this.setState({
          usersMap: usersMap,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  setLoadingSecondaryView(value: boolean) {
    this.setState({loadingSecondaryView: value});
  }

  getActions(): TwoAction[] {
    const actions: TwoAction[] = [];
    actions.push({
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.setState({showEditDialog: true});
      },
    });
    actions.push({
      icon: faPlus,
      label: 'Add event',
      main: false,
      action: () => {
        this.setState({showAddEventDialog: true});
      },
    });
    return actions;
  }

  onHideEditDialog() {
    this.setState({showEditDialog: false});
  }

  onHideAddEventDialog() {
    this.setState({showAddEventDialog: false});
  }

  render() {
    const {job, eventItems, usersMap, loadingJob, loadingSecondaryView, showEditDialog, showAddEventDialog} =
      this.state;
    if (!job) {
      return <></>;
    }

    return (
      <>
        <TwoEntityComponent title={job.title} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!loadingJob ? <JobDetail job={job} usersMap={usersMap} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!loadingSecondaryView ? <TwoTimeline key={job.id} items={eventItems} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <EditJobDialog showDialog={showEditDialog} onHide={this.onHideEditDialog} toast={this.toast} job={job} />

        <AddEventDialog
          showDialog={showAddEventDialog}
          onHide={this.onHideAddEventDialog}
          toast={this.toast}
          job={job}
        />

        <Toast ref={this.toast} />
      </>
    );
  }
}

export default withRouter(JobComponent);
