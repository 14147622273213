import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Job, JobPatch, QueryParameter} from 'two-core';

export default class JobsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.jobs ?? '';
  }

  async getJobs(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateJob(id: string, data: JobPatch): Promise<Job> {
    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as Job);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}
