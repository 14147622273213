import {AdditionalItemType, FixingRequiredType, JobStage, ParkingType, PropertyType, RequestedServices} from 'two-core';

export const jobStageOptions: JobStage[] = ['New', 'Measure Review'];
export const requestedServicesOptions: RequestedServices[] = [
  'Consult & Install',
  'Check Measure & Install',
  'Service Call',
  'Repair',
];
export const propertyTypeOptions: PropertyType[] = ['Unit', 'House', 'Town House'];
export const fixingRequiredTypeOptions: FixingRequiredType[] = ['Brick', 'Timber', 'Tile', 'Concrete'];
export const additionalItemTypeOptions: AdditionalItemType[] = [
  'Extension Ladder',
  'Scaffold',
  'A Frame',
  'Step ladder',
  'Harness',
];
export const parkingTypeOptions: ParkingType[] = [
  'Street',
  'Metered',
  'Parking Lot',
  'Construction Site',
  'Park House',
];
