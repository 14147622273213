import React from 'react';
import {
  AppContext,
  MessageService,
  TleReferenceComponent,
  ToastService,
  TwoDataTable,
  TwoMessage,
  UsersService,
} from 'two-app-ui';
import JobsService from '../../services/JobsService';
import {Toast} from 'primereact/toast';
import {Job, JobStage, MapOf, QueryParameter, TwoType, User} from 'two-core';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import {DropdownChangeParams} from 'primereact/dropdown';
import config from '../../config/config';
import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {NavLink} from 'react-router-dom';

interface Props {
  defaultFilters?: {stage?: JobStage; state_id?: string};
}
interface State {
  items: Job[];
  usersMap: MapOf<User>;
  selectedItems: Job[];
  totalItems: number;
  loading: boolean;
  filters: {};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}
export default class JobList extends React.Component<Props, State> {
  static contextType = AppContext;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  jobsService: JobsService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      usersMap: {},
      selectedItems: [],
      totalItems: 0,
      loading: true,
      filters: {},
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.summaryBodyTemplate = this.summaryBodyTemplate.bind(this);
    this.measuredByBodyTemplate = this.measuredByBodyTemplate.bind(this);
    this.lastActivityBodyTemplate = this.lastActivityBodyTemplate.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleChangeSelectedItems = this.handleChangeSelectedItems.bind(this);
    this.requestedServicesBodyTemplate = this.requestedServicesBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.jobsService = this.context.jobsService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.jobUpdated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage?.name === 'top-selection-changed') {
          this.loadData();
        }
      }
    });
    this.loadData();
    this.loadUsers();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});

    const filters: string[] = [];
    const sortBy: string[] | undefined = [];
    const defaultFilters = this.props.defaultFilters;

    if (defaultFilters?.stage) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: defaultFilters.stage,
        })
      );
    }

    if (defaultFilters?.state_id) {
      filters.push(
        JSON.stringify({
          field: 'state_id',
          value: defaultFilters.state_id,
        })
      );
    }

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      orderBys: sortBy,
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
    };

    this.jobsService
      ?.getJobs(params)
      .then(data => {
        const items = (data?.records as Job[]) ?? [];
        this.setState({
          items: items,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];
        const usersMap: MapOf<User> = {};
        for (const user of users) {
          usersMap[user.id!] = user;
        }

        this.setState({
          usersMap: usersMap,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => {
      this.loadData();
    });
  }

  onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => {
      this.loadData();
    });
  }

  onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => {
        this.loadData();
      }
    );
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  handleChangeSelectedItems(items: TwoType[]) {
    this.setState({selectedItems: items as Job[]});
  }

  jobCodeBodyTemplate(rowData: Job) {
    return <NavLink to={'/job/' + rowData.id}>{rowData.title}</NavLink>;
  }

  requestedServicesBodyTemplate(rowData: Job) {
    return <div>{rowData.requested_services}</div>;
  }

  summaryBodyTemplate(rowData: Job) {
    //const dcm = rowData.documents?.find(
    //  doc => doc.type === 'DCM' && doc.stage === 'Measure Released'
    //);
    return '';
  }

  measuredByBodyTemplate(rowData: Job) {
    const dcm = rowData.documents?.find(doc => doc.type === 'DCM' && doc.stage === 'Measure Released');
    if (dcm?.released_by?.label) {
      return dcm.released_by.label;
    }
    return '';
  }

  lastActivityBodyTemplate(rowData: Job) {
    const lastTle = rowData?.last_tle;
    if (lastTle?.id) {
      const user = this.state.usersMap[lastTle.recorded_by];
      return <TleReferenceComponent identifier={rowData.id ?? ''} value={lastTle} user={user} />;
    } else {
      return <></>;
    }
  }

  renderTable() {
    const {selectedItems, filters, loading, items, totalItems, pagination, sortBy} = this.state;
    return (
      <TwoDataTable
        activeFilters={filters}
        selectedItems={selectedItems}
        pageSizeIdentifier="order_list_page_container"
        loading={loading}
        value={items}
        totalRecords={totalItems}
        rows={pagination.pageSize}
        first={pagination.offset}
        sortField={sortBy?.field}
        sortOrder={sortBy?.order}
        onPage={this.onPageChange}
        onSort={this.onSort}
        selectionMode="multiple"
        handleChangeSelectedItems={this.handleChangeSelectedItems}
        showSelectAll={false}
      >
        <Column header="Job Code" field="order_id" body={this.jobCodeBodyTemplate} style={{width: '100px'}} />
        <Column
          header="Type"
          field="requested_services"
          body={this.requestedServicesBodyTemplate}
          style={{width: '100px'}}
        />
        <Column header="Summary" field="summary" body={this.summaryBodyTemplate} style={{width: '100px'}} />
        <Column header="Measured By" field="measured_by" body={this.measuredByBodyTemplate} style={{width: '100px'}} />
        <Column
          header="Last Activity"
          field="last_activity"
          body={this.lastActivityBodyTemplate}
          style={{width: '100px'}}
        />
      </TwoDataTable>
    );
  }

  render() {
    return (
      <div id={'order_list_page_container'} className="page-container">
        {this.renderTable()}
        <Toast ref={this.toast} />
      </div>
    );
  }
}
