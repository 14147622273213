import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter} from 'two-core';

class StatesService extends ApiService {
  endpoint: string;
  endpointStateFittingTeams: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.states ?? '';
    this.endpointStateFittingTeams = config().endpoints.stateFittingTeams ?? '';
  }

  async getStates(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async getStateFittingTeams(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpointStateFittingTeams, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default StatesService;
