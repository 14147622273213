import React from 'react';
import {JobDocument} from 'two-core';
import {ReferenceComponent, ReferenceType} from 'two-app-ui';
import {faFile} from '@fortawesome/pro-regular-svg-icons';
import './JobDocumentReference.scss';

interface Props {
  document: JobDocument;
}

export class JobDocumentReference extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {document} = this.props;
    const id = document?.id ?? '';
    const stageClass = `stage-${document.stage.toLowerCase().replace(' ', '-')}`;
    return (
      document && (
        <div className={`p-d-flex p-flex-row p-flex-wrap job-document-reference ${stageClass}`}>
          <ReferenceComponent
            key={'job-document-reference-' + id}
            label={document.type}
            index={'job-document-reference-' + id}
            faIcon={faFile}
            type={ReferenceType.DEFAULT}
          />
        </div>
      )
    );
  }
}
