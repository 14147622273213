import React from 'react';
import {Toast} from 'primereact/toast';
import {Job, TimeLineEvent, TleContent, TleContentCall, TleContentSms} from 'two-core';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {InputText} from 'primereact/inputtext';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import TlesService from '../../services/TlesService';

//todo email option will be add later
const typeOptions = ['sms', 'call'];

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  job: Job;
}

interface State {
  showDialog: boolean;
  loading: boolean;
  content: TleContent;
  type: 'sms' | 'call';
}

class AddEventDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
      loading: false,
      content: {},
      type: 'sms',
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.tlesService = this.context.tlesService;
  }

  onHide() {
    this.setState({
      showDialog: false,
      loading: false,
      content: {},
      type: 'sms',
    });
    this.props.onHide();
  }

  async onSave() {
    const {content} = this.state;
    if (Object.keys(content).length) {
      if (this.validate()) {
        this.createEvent();
      }
    } else {
      this.onHide();
    }
  }

  validate(): boolean {
    const {toast} = this.props;
    const {content, type} = this.state;
    const errors: string[] = [];
    if (type === 'sms') {
      const smsContent = content as TleContentSms;
      if (!smsContent?.to?.length) {
        errors.push('To field is empty.');
      }
      if (!smsContent?.from?.length) {
        errors.push('From field is empty.');
      }
      if (!smsContent?.text?.length) {
        errors.push('Text field is empty.');
      }
    } else if (type === 'call') {
      const callContent = content as TleContentCall;
      if (!callContent?.to?.length) {
        errors.push('To field is empty.');
      }
      if (!callContent?.from?.length) {
        errors.push('From field is empty.');
      }
      if (!callContent?.text?.length) {
        errors.push('Text field is empty.');
      }
    } else {
      errors.push('Type field is empty.');
    }
    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.toastService?.showError(toast, errorText);
      return false;
    }
    return true;
  }

  async createEvent() {
    this.setState({loading: true});
    const job = this.props.job!;
    const {type, content} = this.state;

    const newTle: TimeLineEvent = {
      event_type: type,
      entity_type: 'job',
      recorded_by: this.getCurrentUserId(),
      entity_id: job.id!,
      content: content,
      recorded_at: new Date(),
    };
    return this.tlesService
      ?.createTle(newTle)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Event created successfully.');
        MessageService.sendMessage(messages.jobUpdated);
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, creating failed. Please refresh and try again.');
      });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  onChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {content} = this.state;
    const name = e.target.name;

    switch (name) {
      case 'type':
        this.setState({type: e.target.value});
        break;
      case 'from':
      case 'to':
      case 'text':
        this.setState({content: {...content, [name]: e.target.value}});
        break;
      default:
        console.error(`onChange for ${name} not implemented yet`);
    }
  }

  renderValue(key: string) {
    const {type, content} = this.state;
    switch (key) {
      case 'type':
        return <Dropdown name={key} value={type} options={typeOptions} onChange={this.onChange} />;
      case 'from':
      case 'to':
      case 'text':
        return <InputText name={key} value={content[key as keyof TleContent]} onChange={this.onChange} />;
      default:
        return <span>Not implemented yet!</span>;
    }
  }

  renderField(label: string, key: string) {
    return (
      <div className="p-field p-grid" key={key}>
        <label className="p-col-1 p-md-3  p-mb-0">{label}</label>
        <div className="p-col-11 p-md-9">{this.renderValue(key)}</div>
      </div>
    );
  }

  render() {
    const {job} = this.props;
    const {type} = this.state;
    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        {this.renderField('type', 'type')}
        {(type === 'sms' || type === 'call') && (
          <>
            {this.renderField('to', 'to')}
            {this.renderField('from', 'from')}
            {this.renderField('text', 'text')}
          </>
        )}
      </div>
    );

    return (
      <TwoDialog
        headerTitle={`Add event to ${job.title}`}
        showDialog={this.props.showDialog}
        onHide={this.onHide}
        onSave={this.onSave}
        loading={this.state.loading}
        width={70}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEventDialog;
