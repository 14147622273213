import React from 'react';
import {Toast} from 'primereact/toast';
import {Address, InstallationInfo, Job, JobPatch, QueryParameter, State as TwoState} from 'two-core';
import JobsService from '../../services/JobsService';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {InputText} from 'primereact/inputtext';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {
  additionalItemTypeOptions,
  fixingRequiredTypeOptions,
  jobStageOptions,
  parkingTypeOptions,
  propertyTypeOptions,
  requestedServicesOptions,
} from '../../config/constants';
import {MultiSelect} from 'primereact/multiselect';
import StatesService from '../../services/StatesService';
import {InputNumber} from 'primereact/inputnumber';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  job: Job;
}

interface State {
  showDialog: boolean;
  jobPatch: JobPatch;
  loading: boolean;
  stateOptions: string[];
  showAddressDetail: boolean;
}

class EditJobDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  jobsService: JobsService | null = null;
  toastService: ToastService | null = null;
  statesService: StatesService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
      jobPatch: {},
      loading: false,
      stateOptions: [],
      showAddressDetail: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadStates = this.loadStates.bind(this);
    this.toggleAddressDetail = this.toggleAddressDetail.bind(this);
  }

  componentDidMount() {
    this.jobsService = this.context.jobsService;
    this.toastService = this.context.toastService;
    this.statesService = this.context.statesService;
    this.loadStates();
  }

  loadStates() {
    const params: QueryParameter = {
      aggregate: false,
    };
    this.statesService?.getStates(params).then(data => {
      const dataRecords = (data?.records as TwoState[]) ?? [];
      const stateOptions = dataRecords.map(state => state.id!);
      this.setState({stateOptions: stateOptions});
    });
  }

  onShow() {
    // this.setState({loading: true});
  }

  onHide() {
    this.setState({
      showDialog: false,
      jobPatch: {},
      loading: false,
      showAddressDetail: false,
    });
    this.props.onHide();
  }

  async onSave() {
    const {jobPatch} = this.state;
    if (Object.keys(jobPatch).length) {
      if (this.validate()) {
        this.updateJob();
      }
    } else {
      //nothing changed -> close the dialog
      this.onHide();
    }
  }

  validate(): boolean {
    //todo validation
    const {toast} = this.props;
    const errors: string[] = [];
    // const reference = orderPatch.reference ?? order!.reference;
    // if (!reference?.length) {
    //   errors.push('Reference field is empty.');
    // }

    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.toastService?.showError(toast, errorText);
      return false;
    }
    return true;
  }

  async updateJob() {
    this.setState({loading: true});
    const job = this.props.job!;
    const jobPatch = this.state.jobPatch;
    const jobTitle = jobPatch?.title ?? job.title;

    return this.jobsService
      ?.updateJob(job.id!, jobPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Job ${jobTitle} updated successfully.`);
        MessageService.sendMessage(messages.jobUpdated);
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(
          this.props.toast,
          `Sorry, updating ${jobTitle} failed. Please refresh and try again.`
        );
      });
  }

  // onInputReferenceChange(value: string) {
  //   const orderPatch = this.state.orderPatch;
  //   const updatedOrder = {
  //     ...orderPatch,
  //     reference: value,
  //   };
  //   this.setState({orderPatch: updatedOrder});
  // }
  //
  //
  // }

  onChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const job = {...this.props.job};
    const jobPatch = {...this.state.jobPatch};
    const name = e.target.name;
    switch (name) {
      case 'title':
      case 'stage':
      case 'requested_services':
      case 'state_id':
      case 'tags':
      case 'folder':
        jobPatch[name] = e.target.value;
        break;
      case 'property_type':
      case 'site_access':
      case 'parkings':
      case 'fixings_required':
      case 'additional_items':
      case 'time_required':
      case 'fitters_required':
      case 'offsiders_required':
      case 'number_of_removals': {
        jobPatch.installation_info = {
          ...job.installation_info,
          ...jobPatch.installation_info,
          [name]: e.target.value,
        } as InstallationInfo;
        break;
      }
      case 'street':
      case 'suburb':
      case 'lat':
      case 'long': {
        jobPatch.address = {
          ...job.address,
          ...jobPatch.address,
          [name]: e.target.value,
        } as Address;
        break;
      }
      default:
        console.error(`onChange for ${name} not implemented yet`);
    }
    this.setState({jobPatch: jobPatch});
  }

  renderValue(key: string) {
    const {job} = this.props;
    const {jobPatch, stateOptions} = this.state;
    const options = {
      stage: jobStageOptions,
      state_id: stateOptions,
      requested_services: requestedServicesOptions,
      tags: [],
      property_type: propertyTypeOptions,
      parkings: parkingTypeOptions,
      fixings_required: fixingRequiredTypeOptions,
      additional_items: additionalItemTypeOptions,
    };
    switch (key) {
      case 'title':
      case 'folder':
        return <InputText name={key} value={jobPatch?.[key] ?? job[key]} onChange={this.onChange} />;
      case 'stage':
      case 'state_id':
      case 'requested_services':
        return (
          <Dropdown value={jobPatch?.[key] ?? job[key]} options={options[key]} name={key} onChange={this.onChange} />
        );
      case 'tags':
        return (
          <MultiSelect value={jobPatch?.[key] ?? job[key]} options={options[key]} name={key} onChange={this.onChange} />
        );
      case 'fixings_required':
      case 'additional_items':
        return (
          <MultiSelect
            value={jobPatch?.installation_info?.[key] ?? job?.installation_info?.[key]}
            options={options[key]}
            name={key}
            onChange={this.onChange}
          />
        );
      case 'time_required':
      case 'fitters_required':
      case 'offsiders_required':
        return (
          <InputNumber
            value={jobPatch?.installation_info?.[key] ?? job?.installation_info?.[key]}
            name={key}
            onValueChange={this.onChange}
          />
        );
      case 'street':
      case 'suburb':
        return (
          <InputText value={jobPatch?.address?.[key] ?? job?.address?.[key]} name={key} onChange={this.onChange} />
        );
      case 'lat':
      case 'long':
        return (
          <InputNumber
            value={jobPatch?.address?.[key] ?? job?.address?.[key]}
            name={key}
            onValueChange={this.onChange}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={8}
          />
        );
      case 'address': {
        const address = jobPatch?.address ?? job?.address;
        const addressString = `${address?.street ?? ''}, ${address?.suburb ?? ''}, ${address?.postCode ?? ''}, ${
          address?.state ?? ''
        }`;
        return (
          <div className="p-d-flex">
            <div>{addressString}</div>
            <div className="p-ml-2">
              <button className="p-button p-button-text p-p-0" onClick={this.toggleAddressDetail}>
                detail
              </button>
            </div>
          </div>
        );
      }
      default:
        return <span>Not implemented yet!</span>;
    }
  }

  toggleAddressDetail() {
    this.setState({showAddressDetail: !this.state.showAddressDetail});
  }

  renderField(label: string, key: string) {
    return (
      <div className="p-field p-grid" key={key}>
        <label className="p-col-1 p-md-3  p-mb-0">{label}</label>
        <div className="p-col-11 p-md-9">{this.renderValue(key)}</div>
      </div>
    );
  }

  render() {
    const {job} = this.props;
    const {showAddressDetail} = this.state;

    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        {this.renderField('title', 'title')}
        {this.renderField('stage', 'stage')}
        {this.renderField('services', 'requested_services')}
        {this.renderField('state', 'state_id')}
        {this.renderField('tags', 'tags')}
        {this.renderField('distributor', '')}
        {this.renderField('dealership', '')}
        {this.renderField('submitted by', '')}
        {this.renderField('customer', '')}
        {this.renderField('address', 'address')}
        {showAddressDetail && (
          <>
            {this.renderField('street', 'street')}
            {this.renderField('suburb', 'suburb')}
            {this.renderField('latitude', 'lat')}
            {this.renderField('longitude', 'long')}
          </>
        )}
        {this.renderField('other contacts', '')}
        {this.renderField('folder link', 'folder')}
        {this.renderField('property type', 'property_type')}
        {this.renderField('site access', 'site_access')}
        {this.renderField('parking', 'parkings')}
        {this.renderField('fixing required', 'fixings_required')}
        {this.renderField('additional items', 'additional_items')}
        {this.renderField('time required', 'time_required')}
        {this.renderField('fitter(s) required', 'fitters_required')}
        {this.renderField('offsiders(s) required', 'offsiders_required')}
        {this.renderField('number of removals', 'number_of_removals')}
      </div>
    );

    return (
      <TwoDialog
        headerTitle={`Edit job - ${job.title}`}
        showDialog={this.props.showDialog}
        onShow={this.onShow}
        onHide={this.onHide}
        onSave={this.onSave}
        loading={this.state.loading}
        width={70}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default EditJobDialog;
