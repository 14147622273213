import React from 'react';
import {Address, InstallationInfo, Job, JobDocument, MapOf, User} from 'two-core';
import {DateTime, Interval} from 'luxon';
import {Link} from 'react-router-dom';
import {JobDocumentReference} from '../../reference/JobDocumentReference';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';

interface Props {
  job: Job;
  usersMap: MapOf<User>;
}

class JobDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {jobPatch: {}};
  }

  componentDidMount() {
    this.setState({jobPatch: {}});
  }

  renderAge(job: Job) {
    const now = DateTime.now();
    const lastActivityAt = job.last_tle?.recorded_at;
    const lastStageChangeAt = job.last_stage_change_at;
    let lastActivityInterval = 0;
    let lastStageChangeInterval = 0;
    if (lastActivityAt) {
      const lastActivityAtDate = DateTime.fromISO(lastActivityAt.toString());
      lastActivityInterval = Math.floor(Interval.fromDateTimes(lastActivityAtDate, now).length('days'));
    }
    if (lastStageChangeAt) {
      const lastStageChangeAtDate = DateTime.fromISO(lastStageChangeAt.toString());
      lastStageChangeInterval = Math.floor(Interval.fromDateTimes(lastStageChangeAtDate, now).length('days'));
    }
    return `${lastActivityInterval} / ${lastStageChangeInterval}`;
  }

  renderAddress(address: Address) {
    return `${address?.street ?? ''}, ${address?.suburb ?? ''}, ${address?.postCode ?? ''}, ${address?.state ?? ''}`;
  }

  renderFolderLink(folderId: string | undefined) {
    if (!folderId) {
      return <></>;
    }
    return (
      <Link to={{pathname: `https://drive.google.com/open?id=${folderId}`}} target="_blank">
        {folderId}
      </Link>
    );
  }

  renderArray(array: string[] | undefined) {
    if (array) {
      return array.join(', ');
    }
    return <></>;
  }

  renderTeamAndTime(info: InstallationInfo | undefined) {
    const resultArray: string[] = [];
    if (info) {
      if (info.time_required) {
        resultArray.push(info.time_required + ' hours');
      }
      if (info.fitters_required) {
        resultArray.push(info.fitters_required + ' fitter(s)');
      }
      if (info.offsiders_required) {
        resultArray.push(info.offsiders_required + ' off-sider(s)');
      }
    }
    return this.renderArray(resultArray);
  }

  renderDocuments(documents: JobDocument[]) {
    return (
      <div className="p-d-flex p-flex-wrap">
        {documents.map(document => (
          <JobDocumentReference key={document.id} document={document} />
        ))}
      </div>
    );
  }

  render() {
    const {job} = this.props;
    if (!job) {
      return <></>;
    }
    return (
      <>
        <EntityDetailField label="stage">{job.stage}</EntityDetailField>
        <EntityDetailField label="age">{this.renderAge(job)}</EntityDetailField>
        <EntityDetailField label="services">{job.requested_services}</EntityDetailField>
        <EntityDetailField label="state">{job.state_id}</EntityDetailField>
        <EntityDetailField label="tags">{this.renderArray(job.tags)}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="distributor">Not implemented yet!</EntityDetailField>
        <EntityDetailField label="dealership">Not implemented yet!</EntityDetailField>
        <EntityDetailField label="requested by">{job.requested_by.label}</EntityDetailField>
        <EntityDetailField label="customer">Not implemented yet!</EntityDetailField>
        <EntityDetailField label="address">{this.renderAddress(job.address)}</EntityDetailField>
        <EntityDetailField label="other contacts">Not implemented yet!</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="measurer">{job.measured_by?.label ?? ''}</EntityDetailField>
        <EntityDetailField label="installer">{job.installed_by?.label ?? ''}</EntityDetailField>
        <EntityDetailField label="appointments">Not implemented yet!</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="documents">{this.renderDocuments(job.documents ?? [])}</EntityDetailField>
        <EntityDetailField label="folder link">{this.renderFolderLink(job.folder)}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="fixing into">
          {this.renderArray(job.installation_info?.fixings_required)}
        </EntityDetailField>
        <EntityDetailField label="additional tools">
          {this.renderArray(job.installation_info?.additional_items)}
        </EntityDetailField>
        <EntityDetailField label="team and time">{this.renderTeamAndTime(job.installation_info)}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="order code">{job.order_id}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label="related jobs">Not implemented yet!</EntityDetailField>
      </>
    );
  }
}

export default JobDetail;
