export const config = () => {
  const freightService = process.env.REACT_APP_FREIGHT_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const factoryService = process.env.REACT_APP_FACTORY_SERVICE;
  const fittingService = process.env.REACT_APP_FITTING_SERVICE;
  return {
    system: {
      stopTypingDetection: 1000,
    },
    endpoints: {
      states: freightService + 'states',
      stateFittingTeams: fittingService + 'state-fitting-teams',
      tles: tleService + 'tles',
      orders: orderService + 'orders',
      alarms: factoryService + 'alarms',
      jobs: fittingService + 'jobs',
    },
  };
};
export default config;
