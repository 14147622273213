import React from 'react';
import {DropdownOption, QueryParameter, State, StateFittingTeam} from 'two-core';
import './scss/App.scss';
import {
  TwoAppFrame,
  AppMenuItem,
  AppContext,
  AppMenuItemTemplate,
  AppMenuItemSeparator,
  AuthService,
  MessageService,
  ToastService,
  UsersService,
  AuthenticatedUser,
} from 'two-app-ui';

import StatesService from './services/StatesService';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Route, Switch} from 'react-router-dom';
import TlesService from './services/TlesService';
import {MenuItemOptions} from 'primereact/menuitem';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBlinds,
  faBugSlash,
  faCalendarRange,
  faList,
  faMessages,
  faScrewdriverWrench,
  faSirenOn,
  faTape,
} from '@fortawesome/pro-regular-svg-icons';
import JobsService from './services/JobsService';
import JobList from './components/Job/JobList';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import JobComponent from './components/Job/JobComponent';

library.add(faSirenOn);

const authService = new AuthService();
const statesService = new StatesService(authService);
const tlesService = new TlesService(authService);
const toastService = new ToastService();
const jobsService = new JobsService(authService);
const usersService = new UsersService(authService);

interface AppState {
  states: DropdownOption[];
  loadingStates: boolean;
  selectedState?: string;
}

class App extends React.Component<{}, AppState> {
  static contextType = AppContext;
  subscription: Subscription = new Subscription();

  menu: AppMenuItem[];
  constructor(props: {}) {
    super(props);

    this.state = {
      states: [],
      loadingStates: false,
      selectedState: undefined,
    };

    this.onStateChange = this.onStateChange.bind(this);

    this.menu = [
      {
        label: 'Alarms',
        faIcon: faSirenOn,
        badgeId: 'alarmBadge',
        to: '/alarms',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Inbox',
        faIcon: faMessages,
        to: '/inbox',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Measures',
        faIcon: faTape,
        to: '/measures',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Installs',
        faIcon: faBlinds,
        to: '/installs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Repairs',
        faIcon: faBugSlash,
        to: '/repairs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Service Calls',
        faIcon: faScrewdriverWrench,
        to: '/service-calls',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'All jobs',
        faIcon: faList,
        to: '/jobs',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Schedule',
        faIcon: faCalendarRange,
        to: '/schedule',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
    ];
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage()
      .pipe(filter(message => message === 'loggedin'))
      .subscribe(() => {
        this.loadStateFittingTeams();
      });

    this.loadStateFittingTeams();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async onStateChange(e: DropdownChangeParams) {
    this.setState({selectedState: e.value});
    await localStorage.setItem('current state', e.value);
    MessageService.sendMessage({name: 'top-selection-changed', value: e.value});
  }

  async loadStateFittingTeams() {
    const userJsonString = localStorage.getItem('user') ?? '';
    const user: AuthenticatedUser = JSON.parse(userJsonString);

    if (user && user.username) {
      const params: QueryParameter = {
        aggregate: false,
        filters: [
          JSON.stringify({
            field: 'user_id',
            value: user?.uuid,
          }),
          JSON.stringify({
            field: 'role',
            value: 'dispatcher',
          }),
        ],
      };

      statesService?.getStateFittingTeams(params).then(async data => {
        const dataRecords = (data?.records as StateFittingTeam[]) ?? [];
        const stateIds = dataRecords.map(sft => {
          return sft.state_id ?? '';
        });

        this.loadStates(stateIds);
      });
    }
  }

  async loadStates(stateIds: string[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: stateIds,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };

    statesService?.getStates(params).then(data => {
      const dataRecords = (data?.records as State[]) ?? [];
      const states = dataRecords.map(record => {
        return {
          label: record.long_name,
          value: record.id,
        } as DropdownOption;
      });

      let currentStateId = localStorage.getItem('current state') ?? '';
      if ((currentStateId === '' || currentStateId === 'undefined') && states.length > 0) {
        currentStateId = states[0].value as string;
      }
      this.setState({states: states, selectedState: currentStateId});

      if (currentStateId !== undefined) {
        localStorage.setItem('current state', currentStateId);
      }

      MessageService.sendMessage('top-selection-loaded');
    });
  }

  render() {
    const values = {
      statesService: statesService,
      tlesService: tlesService,
      toastService: toastService,
      authService: authService,
      jobsService: jobsService,
      usersService: usersService,
    };

    const stateId = this.state.selectedState ?? '';

    return (
      <TwoAppFrame menuItems={this.menu} contextValues={values}>
        <div className="topframe">
          <div className="dropdown-top">
            <Dropdown
              value={this.state.selectedState}
              options={this.state.states}
              onChange={this.onStateChange}
              placeholder="Select option"
            />
          </div>
        </div>
        <>
          <Switch>
            <Route path="/alarms"></Route>
            <Route path="/inbox"></Route>
            <Route path="/measures">
              <JobList defaultFilters={{stage: 'Measure Review', state_id: stateId}} />
            </Route>
            <Route path="/installs"></Route>
            <Route path="/repairs"></Route>
            <Route path="/service-calls"></Route>
            <Route path="/jobs"></Route>
            <Route path="/schedule"></Route>
            <Route path="/job/:id">
              <JobComponent />
            </Route>
          </Switch>
        </>
      </TwoAppFrame>
    );
  }
}

export default App;
